import React from 'react';
import styles from './Loader.module.css';
import { BallTriangle } from 'react-loader-spinner';

const Loader = () => {
  return(<>
    <div className={styles.Loader}>
    <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#AC6CFF"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass="justify-content-center"
  visible={true}
  />
  
  </div>
  </>)
};

export default Loader;
