import React, { useEffect } from 'react';
import styles from './AboutUs.module.css';
import img2 from '../../Assets/images/hero_bg.png';

const AboutUs = () => {
  const handleClick = () => {
    window.open('https://wa.me/+201092127269', '_blank');
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <section id='aboutus' className={`${styles.AboutUs} sec1`}>
        <div className="title text-center d-flex justify-content-center flex-column align-items-center">
          <h1 className="colorthird about-us-h1 mb-5 mt-5">من نحن</h1>
          <div className="row d-flex justify-content-center align-items-center w-100 ">
            
            {/* Left Image Section */}
            <div className="col-md-6 mb-4">
              <img src={img2} alt="About Us" className=" w-75 rounded" />
            </div>
            
            {/* Right Text Cards Section */}
            <div className="col-md-6 d-flex flex-column align-items-center gap-4">

              {/* Card 1 */}
              <div className={`${styles.card} card shadow-sm p-4 ${styles.textcard}`}>
                <p className="fs-5">
                  نحن منصة تعليمية متخصصة في تعليم البرمجة وعلوم الحاسوب للمبتدئين والمتقدمين. نسعى دائمًا لتقديم تجربة تعليمية ممتعة وفعالة، ونسهم في تطوير مهاراتك التقنية من خلال دورات متخصصة ومناسبة لمستواك.
                </p>
              </div>

              {/* Card 2 */}
              <div className={`${styles.card} card shadow-sm p-4 ${styles.textcard}`}>
                <p className="fs-5">
                  رؤيتنا هي تمكين الطلاب من التفوق في مجالات التكنولوجيا والمساهمة في تعزيز مهاراتهم اللازمة لسوق العمل. نقدم دعمًا مستمرًا ومواد تدريبية متكاملة لتساعدك على تحقيق أهدافك.
                </p>
              </div>

              {/* Card 3 */}
              <div className={`${styles.card} card shadow-sm p-4 ${styles.textcard}`}>
                <p className="fs-5">
                  انضم إلينا الآن وابدأ رحلتك التعليمية في تعلم البرمجة وعلوم الحاسوب بطريقة مبتكرة وحديثة، مع خبراء متخصصين ودورات محدثة باستمرار.
                </p>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
