import React, { useContext, useState } from 'react';
import styles from './NavBar.module.css';
import logo from '../../Assets/images/logo1.png';
import { NavLink, useNavigate } from 'react-router-dom';
import { TokenContext } from '../../Context/Token';

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);  
  const navigate = useNavigate();
  const { token, setToken } = useContext(TokenContext);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  function LogOut() {
    localStorage.removeItem("userToken");
    setToken(null);
    navigate("/login");
    closeMenu(); 
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bgnav navfont fixed-top" dir='rtl'>
        <div className="container">
          <NavLink className="navbar-brand p-0 m-0" onClick={scrollToTop} to={'home'}>
            <img src={logo}  style={{ objectFit: 'cover' , width: '170px' }} alt="" />
          </NavLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={isMenuOpen ? 'true' : 'false'} aria-label="Toggle navigation" onClick={toggleMenu}>
            <span className="navbar-toggler-icon" />
          </button>
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          </ul>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item my-1">
                <NavLink className="nav-link textmain ms-4" activeClassName="active" onClick={() => { scrollToTop(); closeMenu(); }} to={'home'}>الرئيسية</NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link textmain ms-4" activeClassName="active" onClick={() => { scrollToTop(); closeMenu(); }} to={'aboutus'}>حول المنصة</NavLink>
              </li> */}
              <li className="nav-item my-1">
                <NavLink className="nav-link textmain ms-4" activeClassName="active" onClick={() => { scrollToTop(); closeMenu(); }} to={'stages'}>المراحل الدراسية</NavLink>
              </li>
              <div className='my-3'></div>
              {token ? (
                <li className="nav-item">
                  <button className={`${styles.bglogin} nav-link textmain rounded rounded-3 px-3 py-2`} onClick={LogOut}>تسجيل خروج</button>
                </li>
              ) : (
                <li className="nav-item">
                  <NavLink className={`${styles.bglogin} nav-link textmain rounded rounded-3 px-3 py-2 `} onClick={closeMenu} to={'login'}>تسجيل الدخول</NavLink>
                </li>
              )}
            </ul>
            
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
