import React, { useState, useEffect, useContext } from 'react';
import styles from './Courses.module.css';
import { Link, useParams } from 'react-router-dom';
import { ContentContext } from '../../Context/ContentContext';
import Loader from '../Loader/Loader';

const Courses = () => {
  const { id } = useParams();
  const { getAllCourses } = useContext(ContentContext);

  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await getAllCourses();
        const filteredCourses = data.courses.filter(course => course.subcategory_id === parseInt(id));
        console.log('Filtered Courses:', filteredCourses);
        setCourses(filteredCourses);
      } catch (error) {
        console.error('Error fetching courses:', error);
        setError('حدث خطأ أثناء جلب الدروس.');
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [id, getAllCourses]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <section className={styles.coursesSection}>
        <div className="container pt-3 mt-3">
          <div className="tile d-flex justify-content-end align-items-center">
            <h2 className="fw-bold text-end pe-5">
                  الكورسات
              <span>
                <i
                  className={`${styles.pointer} fa-solid fs-4 ms-2 colorthird fa-right-long`}
                  onClick={() => window.history.back()} // Add click handler to go back
                ></i>
              </span>
            </h2>
          </div>

          <div className="row gx-0 pt-4 mt-5 d-flex justify-content-between align-items-end">
            {loading ? (
              <Loader />
            ) : error ? (
              <p className="text-center">{error}</p>
            ) : courses.length > 0 ? (
              courses.map(course => (
                <div key={course.id} className="col-12 col-md-6 col-lg-4 mb-3 p-4">
                  <Link to={`/ourlessons/${course.id}`} className="text-decoration-none text-center" onClick={scrollToTop}>
                    <div className={`${styles.card}`}>
                      <img
                        src={`https://el-manasa.mediagrafico.com/${course.image}`}
                        alt={course.title}
                        className="img-fluid card-img-top"
                      />
                      <div className="card-body">
                        <h5 className="colorthird fw-bolder fs-4">{course.title}</h5>
                        <p className="card-text">{course.description}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <p className="text-center">لا توجد دورات للعرض.</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Courses;
