import React, { useState, useEffect, useContext } from 'react';
import styles from './SubCategory.module.css';
import { Link, useParams } from 'react-router-dom';
import { ContentContext } from '../../Context/ContentContext';
import Loader from '../Loader/Loader';
import Modal from '../Modal/Modal'; // Import the Modal component
import { TokenContext } from '../../Context/Token';

const SubCategory = () => {
  const { id } = useParams();
  const { getAllSubcategories } = useContext(ContentContext);
  let {token}=useContext(TokenContext)

  const [subcategories, setSubcategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(true); // State to control modal visibility

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchSubcategories = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await getAllSubcategories();
        const filteredSubcategories = data.subcategories.filter(sub => sub.category_id === parseInt(id));
        setSubcategories(filteredSubcategories);
      } catch (error) {
        console.error('Error fetching subcategories:', error);
        setError('حدث خطأ أثناء جلب التصنيفات الفرعية.');
      } finally {
        setLoading(false);
      }
    };

    fetchSubcategories();
  }, [id, getAllSubcategories]);

  // Close the modal when the user clicks the close button
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
     {token? <section className={styles.sub}>
        <div className="container pt-5 mt-5 ">
          <div className="row pt-5 mt-5 d-flex justify-content-center align-items-center">
            {loading ? (
              <Loader />
            ) : error ? (
              <p className="text-center">{error}</p>
            ) : subcategories.length > 0 ? (
              subcategories.map(subcategory => (
                <div key={subcategory.id} className="col-12 col-md-6 col-lg-4 mb-3">
                  <Link className="text-decoration-none text-center" to={`/courses/${subcategory.id}`}>
                    <div className={styles.bg}>
                      <h5 className="colorthird p-3">{subcategory.name}</h5>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <p className="text-center">لا توجد تصنيفات فرعية للعرض.</p>
            )}
          </div>
        </div>
      </section>: <>
      <section className={styles.sub}>
        <div className="container pt-5 mt-5 ">
          <div className="row pt-5 mt-5 d-flex justify-content-center align-items-center">
            {loading ? (
              <Loader />
            ) : error ? (
              <p className="text-center">{error}</p>
            ) : subcategories.length > 0 ? (
              subcategories.map(subcategory => (
                <div key={subcategory.id} className="col-12 col-md-6 col-lg-4 mb-3">
                  <Link className="text-decoration-none text-center" to={`/courses/${subcategory.id}`}>
                    <div className={styles.bg}>
                      <h5 className="colorthird p-3">{subcategory.name}</h5>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <p className="text-center">لا توجد تصنيفات فرعية للعرض.</p>
            )}
          </div>
        </div>
      </section>
      
      {showModal && <Modal closeModal={closeModal} />}

      
      </>}

    </>
  );
};

export default SubCategory;
