import React from 'react';
import { Navigate } from 'react-router-dom';

export default function ProtectedRoutesX({ children }) {
  const token = localStorage.getItem("userToken");

  if (token !== null) {
    return <Navigate to={'/home'} />;
  }

  return children;
}
