import React, { useEffect, useState } from 'react';
import styles from './Lessons.module.css';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Loader from '../Loader/Loader';

const Lessons = () => {
  const { id } = useParams(); // Get the lesson ID from the URL
  const [lesson, setLesson] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchLessons = async () => {
      try {
        const token = localStorage.getItem("userToken");
        const response = await axios.get('https://el-manasa.mediagrafico.com/api/lessons', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const lessons = response.data.lessons;
        console.log(lessons);
        
        const foundLesson = lessons.find(lesson => lesson.id === parseInt(id));
        setLesson(foundLesson);
      } catch (err) {
        setError('Error fetching lessons');
        console.error("Error fetching lessons:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchLessons();
  }, [id]);

  if (loading) return (
    <section id="content" className={`${styles.content} sec2 pt-5 text-center`}>
      <div className="container mt-5 pt-4">
        <div className="d-flex justify-content-center align-items-center pt-5 vh-50">
          <Loader /> 
        </div>
      </div>
    </section>
  );

  if (error) return <p>{error}</p>;
  if (!lesson) return <p>Lesson not found</p>;

  const hasContent = lesson.content || lesson.video_url || lesson.pdf_url;

  const videoURL = lesson.video_url && lesson.video_url.startsWith('http')
    ? lesson.video_url
    : `https://el-manasa.mediagrafico.com/${lesson.video_url}`;

    const pdfURL = lesson.pdf_url && lesson.pdf_url.startsWith('http')
  ? lesson.pdf_url
  : `https://el-manasa.mediagrafico.com/${lesson.pdf_url}`;

  return (
    <section id="content">
      <div className={`${styles.content} sec2 pt-5 text-center`}>
        <div className="container mt-5 pt-4">
          <h2 className="fw-bold text-end pe-5">
            {lesson.title} 
            <span>
              <i
                className={`${styles.pointer} fa-solid fs-4 ms-2 colorthird fa-right-long`}
                onClick={() => window.history.back()} // Click handler to go back
              ></i>
            </span>
          </h2>
          <div className="row d-flex flex-column pt-5 align-items-center">
            <div className="col-12">
              {hasContent ? (
                <>
                  {lesson.video_url && (
                    <div className="video my-5">
                      <video controls className="img-fluid w-50">
                        <source src={videoURL} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}

                  <div className={`${styles.explainborder}`}>
                    <h5 className='fw-bolder mb-2 mt-4'>شرح الدرس</h5>
                    {lesson.content ? (
                      <>
                        <h6 className={`${styles.explain}`}>{lesson.content}</h6> {/* Lesson description */}
                        <div className="bg-white p-4 mt-4 rounded-3">
                          <p>{lesson.content}</p> {/* Lesson details */}
                        </div>
                      </>
                    ) : (
                      <p>لا يوجد محتوى لهذا الدرس</p> // Fallback if no content
                    )}

                    <div className="d-flex justify-content-center my-5 align-items-center flex-column flex-md-row">
                      {lesson.pdf_url && (
                        <a
                          href={pdfURL} // PDF link
                          download
                          className={`${styles.btn1} fa-solid fa-file-pdf text-decoration-none bg-transparent colorthird p-3`}
                          role="button"
                        >
                          تحميل PDF
                        </a>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <p>لا يوجد محتوى متاح لهذا الدرس</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Lessons;
