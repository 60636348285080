import React from 'react';
import styles from './Footer.module.css';
import logo from '../../Assets/images/logo1.png'

const Footer = () => {
  return (<>
    <section className='footer'>
      <div className={`${styles.bgfooter} textmain`} dir='rtl'>
        <div className="container">
          <div className="logo">
            <img src={logo} className='rounded-2' 
              alt="logo" width={200} />
          </div>
          <div className="row ">
            <div className="col-md-5">
              <h3 className='mt-3'>
                مستر / احمد خالد
              </h3>
              <div className={styles.textfooter}>
                <h5>
                  خبرة بالمدارس الدولية واللغات داخل وخارج مصر
                </h5>
                <h5>
                  الثانوية العامة ومراحل التأسيس للغة الانجليزية
                </h5>
                <h5>
                  حاصل على شهادة ال TEFL الدولية ومدرب معتمد
                </h5>
              </div>
            </div>
            <div className="col-md-7">
            <h6 className='colorthird mb-4 fs-4 fw-bold'>
            تواصل معنا 
            </h6>
            <div className="ul">
              <li className=' list-unstyled'>
                <span> <i className={`${styles.iconcircle} fa-solid colorthird   fa-location-dot`} />     فوة شارع العبور  امام مدرسة الشهيد أحمد حماد          </span>
              </li>
              <li className=' list-unstyled'>
              <span> <i className={`${styles.iconcircle} fa-solid colorthird fa-phone`} />01095909734</span>

              </li>
              <li className=' list-unstyled'>
              <span> <i className={`${styles.iconcircle} fa-solid colorthird fa-phone`} />01092127269</span>

              </li>
              <li className=' list-unstyled'>
              <span> <i className={`${styles.iconcircle} fa-brands colorthird  fa-facebook`} /> اتعلم مع مستر / احمد خالد </span>

              </li>

            </div>
            </div>
          </div>
          <hr className={styles.line} />
          <div className="container d-flex justify-content-between">
          <h6>Terms & Condition</h6>


            <h6>Grafico -media egancy
            <h6 className='fs-6'>All right reserved ©</h6>

            </h6>
          
          </div>
        </div>
      </div>
    </section>
  </>)
};

export default Footer;
