import axios from 'axios';
import { createContext } from 'react';

export let ContentContext = createContext();

// =========================================================User=======================================


// =====================================================Courses =================================================================
function getAllCourses() {
    const token = localStorage.getItem("userToken");
    return axios.get(`https://el-manasa.mediagrafico.com/api/courses`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error fetching courses:", err);
        throw err;
    });
}

// =====================================================Categories =================================================================

function getAllCategories() {
    return axios.get(`https://el-manasa.mediagrafico.com/api/categories`
    )
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error fetching categories:", err);
        throw err;
    });
}

// ===================================================== Subcategories ==================================
function getAllSubcategories() {
    return axios.get(`https://el-manasa.mediagrafico.com/api/subcategories`)
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error fetching subcategories:", err);
        throw err;
    });
}

// ======================================================Lessons=============================================

function getAllLessons() {
    const token = localStorage.getItem("userToken");
    return axios.get(`https://el-manasa.mediagrafico.com/api/lessons`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error fetching lessons:", err);
        throw err;
    });
}



// ======================= ContentContext Provider ==================================
export default function ContentContextProvider(props) {
    return (
        <ContentContext.Provider 
            value={{ 
                getAllCourses, 
                getAllCategories,
                getAllSubcategories,
                getAllLessons
                       
            }}
        >
            {props.children}
        </ContentContext.Provider>
    );
}
