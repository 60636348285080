import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { TokenContext } from '../../Context/Token';

export default function ProtectedRoutes({ children }) {
  const { token } = useContext(TokenContext);

  const storedToken = token || localStorage.getItem("userToken");

  if (!storedToken) {
    return <Navigate to={'/login'} />;
  }

  return children;
}
