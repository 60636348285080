import React from 'react';
import styles from './NotFound.module.css';

const NotFound = () => {
  return(<>
  <section className={styles.notfound}>

  </section>
  </>)
};

export default NotFound;
