import React, { useContext, useState } from "react";
import styles from "./Home.module.css";
import rocket from "../../Assets/images/63d87e1c432c751c8c28876670d52e34.png";
import img from "../../Assets/images/about3.png";
import AboutUs from "../AboutUs/AboutUs";
import Stages from "../Stages/Stages";
import { TokenContext } from "../../Context/Token";

const Home = () => {
  // const { token, setToken } = useContext(TokenContext);
  // const [showModal, setShowModal] = useState(true); // Initial state to show the modal

  // const closeModal = () => {
  //   setShowModal(false);
  // };
  const handleClick = () => {
    window.open("https://wa.me/+201092127269", "_blank");
  };
  return (
    <>
      <section className={styles.home}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 text-end text-md-end text-center">
              <h2 className="pt-5 mt-5 textmain">
                مرحباً بكم فى أكبر{" "}
                <span className={`h2 ${styles.colorthird}`}>منصة تعليمية</span>
              </h2>
              <h2 className="textmain">
                لمادة اللغة الانجليزية مع كبير متدربي المادة
              </h2>
              <h2 className={styles.colorthird}>مستر / احمد خالد</h2>

                <button
                  onClick={handleClick}
                  className={`${styles.bgsec} bg-transparent fs-3 text-white border-0`}
                >
                  احجز معانا دلوقتي <i className="fa-brands fa-whatsapp"></i>
                </button>

            </div>
            <div className="col-md-6 text-center text-md-start">
              <img src={img} className="w-75" alt="Rocket" />
            </div>
          </div>
        </div>
      </section>
      <AboutUs />
      <Stages />
    </>
  );
};

export default Home;
