import React, { useState, useEffect, useContext } from 'react';
import styles from './OurLessons.module.css';
import { Link, useParams } from 'react-router-dom';
import { ContentContext } from '../../Context/ContentContext';
import Loader from '../Loader/Loader';

const OurLessons = () => {
  const { id } = useParams(); // Get the course ID from the URL params
  const { getAllLessons } = useContext(ContentContext); // Fetch the lessons

  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLessons = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await getAllLessons();
        // Filter lessons by the course ID
        const filteredLessons = data.lessons.filter(lesson => lesson.course_id === parseInt(id));
        console.log('Filtered Lessons:', filteredLessons);
        setLessons(filteredLessons);
      } catch (error) {
        console.error('Error fetching lessons:', error);
        setError('حدث خطأ أثناء جلب الدروس.');
      } finally {
        setLoading(false);
      }
    };

    fetchLessons();
  }, [id, getAllLessons]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <section className={styles.coursesSection}>
        <div className="container pt-3 mt-3">
          <div className="tile d-flex justify-content-end align-items-center">
            <h2 className="fw-bold text-end pe-5">
              الدروس
              <span>
                <i
                  className={`${styles.pointer} fa-solid fs-4 ms-2 colorthird fa-right-long`}
                  onClick={() => window.history.back()} // Add click handler to go back
                ></i>
              </span>
            </h2>
          </div>

          <div className="row gx-0 pt-4 mt-5 d-flex justify-content-between align-items-end">
            {loading ? (
              <Loader />
            ) : error ? (
              <p className="text-center">{error}</p>
            ) : lessons.length > 0 ? (
              lessons.map(lesson => (
                <div key={lesson.id} className="col-12 col-md-6 col-lg-4 mb-3 p-4">
                  <Link to={`/lessons/${lesson.id}`} className="text-decoration-none text-center" onClick={scrollToTop}>
                    <div className={`${styles.card}`}>
                      <img
                        src={`https://el-manasa.mediagrafico.com/${lesson.image}`}
                        alt={lesson.title}
                        className="img-fluid card-img-top"
                      />
                      <div className="card-body">
                        <h5 className="colorthird fw-bolder fs-4">{lesson.title}</h5>
                        <p className="card-text">{lesson.content ? lesson.content : 'لا يوجد محتوى لهذا الدرس.'}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <p className="text-center">لا توجد دروس للعرض.</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default OurLessons;
