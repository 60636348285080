import { createContext, useState, useEffect } from "react";

export let TokenContext = createContext();

export default function TokenContextProvider(props) {
    const [token, setToken] = useState(null);

    useEffect(() => {
        const storedToken = localStorage.getItem("userToken");
        if (storedToken) {
            setToken(storedToken);
        }
    }, []);

    return (
        <TokenContext.Provider value={{ token, setToken }}>
            {props.children}
        </TokenContext.Provider>
    );
}
