import React from 'react';
import styles from './Modal.module.css';

const Modal = ({ closeModal }) => {
  return (
    <div className='text-end '>
      <div className="modal d-block" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered">
          <div className={`modal-content ${styles['modal-content']}`}> {/* Apply animation */}
            <div className="modal-header d-flex justify-content-between">
              <button
                type="button"
                className="fa-solid border-0 bg-transparent fs-2 fa-xmark me-auto"
                aria-label="Close"
                onClick={closeModal} // Close the modal when clicked
              />
              <h5 className="modal-title">مرحبًا بك</h5>
            </div>

            <div className="modal-body">
              <p>
                يجب عليك/ي الاشتراك أولاً عن طريق مراسلتنا على الواتساب
                ثم تسجيل الدخول لأستعراض المحتوى بالكامل
              </p>

              <div className="border-0 d-flex justify-content-center">
                <button
                  className={`${styles.bgsec} border-0 sec textmain d-flex justify-content-center align-items-center rounded-2 px-4 py-3`}
                  onClick={() => window.open('https://wa.me/+201092127269', '_blank')} // WhatsApp link
                >
                  <h6 className='fs-3'>
                    احجز معانا دلوقتي <i className="fa-brands fa-whatsapp"></i>
                  </h6>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
